import React, { Suspense } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { isParamPresentInURL } from "../utils/universalHelperFunctions";
import ErrorBoundary from "../components/ErrorBoundary";
import { Header, SliceZone, Footer } from "components";
import "../styles/index.css";
import PopupModal from "../components/PopupModal";
import AlertModal from "../components/AlertModal";

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.homepagEventsData = [];
    this.state = {
      isOpen: false
    };
  this.handleWindowClose = this.handleWindowClose.bind(this);
  } 

  componentDidMount() {
    window.addEventListener('mouseout', this.handleWindowClose);
    var url = window.location.href || '';
    if (isParamPresentInURL('id', url)) {
      let urlParams = new URLSearchParams(window.location.search);
      let value = urlParams.get('id');
      sessionStorage.setItem('email', value);
    }
    if (sessionStorage.getItem('email')) {
      let emailID = sessionStorage.getItem('email');
      let requestBody = `actid=${process.env.ACTIVE_CAMPAIGN_ID}&key=${process.env.ACTIVE_CAMPAIGN_KEY}&event=site_visited&eventdata=${window.location.pathname}&visit=%7B%22email%22%3A%22${emailID}%22%7D`;
      fetch(process.env.ACTIVE_CAMPAIGN_EVENT_REG_URL, {
        method: 'POST',
        mode: 'no-cors',
        cache: 'no-cache',
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        },
        body: requestBody
      })
        .then(resp => {
          console.log(resp);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  handleWindowClose(event) {
    if (event.clientY < 0 && !this.isAlertClosed()) {
      this.toggle()
    }
  }

  onCloseAlert = () => {
    document.cookie = `isAlertClosed=${true};`;
    console.log('alert closed');
    this.toggle();
  }

  isAlertClosed() {
    let cookiearray = document.cookie.split(';')
    let isclose = false;
    cookiearray.forEach(item => {
      const cookie = item.split('=');
      if (cookie[0].trim() === "isAlertClosed") {
        isclose = cookie[1] === 'true';
      }
    });
    return isclose;
  }

  componentDidMount() {
    window.addEventListener('mouseout', this.handleWindowClose);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseout', this.handleWindowClose);
  }


  getFeaturedResourceDataHomePage(resourceId) {
    this.allPrismicResource.edges.map((item, index) => {
    });
  }

  render() {
    var isWindow = true; // typeof window !== 'undefined';
    let homepageFeaturedResourceData = [];
    let homepageEventsData = [];

    const {
      data: {
        homepage,
        heroMenu,
        serviceContentCardData,
        footerData,
        allPrismicPage,
        allPrismicResource,
        allPrismicJobDescriptionPage,
        prismicPopupModal
      }
    } = this.props;

    let heroContent = {};

    homepage.data.page_content.map((item, index) => {
      if (item.__typename === "PrismicHomepagePageContentResources") {
        if (item.primary.resource_type === "Events") {
          item.items.map((item, index) => {

            getEventsDataHomePage(item);

          });
        } else if (
          item.primary.resource_type === "Featured Resources"
        ) {
          item.items.map((item, index) => {
            getFeaturedResourceDataHomePage(item.resource_uid.text);
          });
        }
      }
      if (item.__typename === 'PrismicHomepagePageContentHeroes') {
        const [heroBannerItem = {}] = item.items || [];
        const { hero_background_image = {} } = heroBannerItem
        heroContent = hero_background_image;
        // console.log(":: heroContent ::", heroContent);
      }
    });

    function getEventsDataHomePage(itemEventData) {
      allPrismicResource.edges.map((item, index) => {
        if (item.node.uid === itemEventData.resource_uid.text) {
          let requiredEventResource = {};

          requiredEventResource.uid = item.node.uid;

          requiredEventResource.title =
            item.node.data.resource_display_name.text;

          requiredEventResource.page_thumbnail =
            itemEventData.event_background_image;

          requiredEventResource.event_size = itemEventData.event_size;
          requiredEventResource.event_type = itemEventData.event_type;
          homepageEventsData.push(requiredEventResource);
        }
      });
    }

    function getFeaturedResourceDataHomePage(resource) {
      allPrismicResource.edges.map((item, index) => {
        if (item.node.uid === resource) {
          const itemBody = item.node.data.body;
          let requiredDataFeaturedResource = {};
          const indexForResourceType = obj =>
            obj.__typename === "PrismicResourceBodyResourceOverview";
          const indexForThimbnail = obj =>
            obj.__typename === "PrismicResourceBodyPageOverview";
          const indexForResourceTag = obj =>
            obj.__typename === "PrismicResourceBodyResourceTags";
          const indexResourceType = itemBody.findIndex(indexForResourceType);
          const indexThumbnail = itemBody.findIndex(indexForThimbnail);
          const indexResourceTag = itemBody.findIndex(indexForResourceTag);
          requiredDataFeaturedResource.uid = item.node.uid;
          requiredDataFeaturedResource.resource_type = itemBody[indexResourceType].primary.resource_type;
          requiredDataFeaturedResource.page_thumbnail = itemBody[indexThumbnail].primary.page_thumbnail;
          requiredDataFeaturedResource.title = item.node.data.resource_display_name.text;
          if (itemBody[indexResourceTag] && itemBody[indexResourceTag].items && itemBody[indexResourceTag].items.length > 0) {
            requiredDataFeaturedResource.resourceTags = itemBody[indexResourceTag];
          }
          homepageFeaturedResourceData.push(requiredDataFeaturedResource);
        }
      });
    }
    let metaDataSlice = homepage.data.page_content.findIndex(function (sl) {
      return sl.slice_type === "metadata_section";
    });
    let overviewSlice = homepage.data.page_content.findIndex(function (sl) {
      return sl.slice_type === "page_overview";
    });
    let metaDataContent = homepage.data.page_content[metaDataSlice].primary;
    let pageTitle = homepage.data.page_content[overviewSlice].primary.page_title.text;
    let pageURL = this.props.location.href;

    //this flag is marked as a false if we are on an other page than resource landing page
    //We are maintaining the filter only for resource pages and internal navigations and not throughout the website
    if (typeof localStorage !== 'undefined') {
      let resourceFlag = localStorage.getItem('resourceFlag');
      if (resourceFlag && resourceFlag === 'true') {
        localStorage.setItem('resourceFlag', 'false');
      }
      let breadCrumbFlag = localStorage.getItem('breadCrumbFlag');
      if (breadCrumbFlag && breadCrumbFlag === 'true') {
        localStorage.setItem('breadCrumbFlag', 'false');
      }
      let searchWordFlag = localStorage.getItem('searchWordFlag');
      if (searchWordFlag && searchWordFlag === 'true') {
        localStorage.setItem('searchWordFlag', 'false');
      }
      let resourceTagFlag = localStorage.getItem('resourceTagFlag');
      if (resourceTagFlag && resourceTagFlag === 'true') {
        localStorage.setItem('resourceTagFlag', 'false');
      }
      let jobFilterFlag = localStorage.getItem('jobFilterFlag');
      if (jobFilterFlag && jobFilterFlag === 'true') {
        localStorage.setItem('jobFilterFlag', 'false');
      }
    }
    // console.log(":: props ::", this, this.props);
    return (
      <ErrorBoundary>
        <div className="col-12 p-0 index-page">
          <Helmet>
            {/* General tags */}
            <title>{pageTitle}</title>
            <meta property="og:title" content="GSPANN | Consulting Services, Technology Services, and IT Services Provider" />
            <meta property="og:url" content={pageURL} />
            <meta property="og:type" content="article" />
            <meta property="og:locale" content="en_US" />
            <meta property="og:description" content={metaDataContent.metadata_description.text} />
            <meta property="og:image" content={heroContent?.Desktop?.localFile?.childImageSharp?.fluid?.originalImg} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:image" content={heroContent?.Desktop?.localFile?.childImageSharp?.fluid?.originalImg} />

            <meta
              name="description"
              content={metaDataContent.metadata_description.text}
            />
            <meta
              name="keywords"
              content={metaDataContent.metadata_keywords.text}
            />
            <meta name="robots" content={metaDataContent.metadata_robots} />
            <meta http-equiv="Pragma" content="no-cache" />
            <meta http-equiv="Expires" content="0" />
            <meta name="google-site-verification" content="Fy8VROwdCIk4KmBbEnJGko3-ZXm9agz417MGKGPUBP8" />
            <meta http-equiv="X-UA-Compatible" content="IE=11" />
            <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, shrink-to-fit=no" />
            <link rel="canonical" href={pageURL} hrefLang="en" />
            <link rel="alternate" href={pageURL} hrefLang="en" key="en" />
          </Helmet>
          <Header
            menuData={heroMenu.data.nav}
            pagesLinksData={allPrismicPage.edges.concat(allPrismicResource.edges)}
          />
          <main role="main" id="main-searchcontainer"></main>
          {
            isWindow && (
              <>
                <SliceZone
                  allSlices={homepage.data.page_content}
                  homePageFeaturedResourceData={homepageFeaturedResourceData}
                  homepageEventsData={homepageEventsData}
                  resourceTilesData={allPrismicResource.edges}
                  pagesLinksData={allPrismicPage.edges}
                  pageId={homepage.uid}
                  jobListData={allPrismicJobDescriptionPage.edges}
                  prismicPopupModal={prismicPopupModal.data}
                />
                 <AlertModal allSlices={homepage.data.page_content}
                  isOpen={this.state.isOpen}
                  prismicPopupModal={prismicPopupModal.data}
                  onCloseAlert={this.onCloseAlert} />
                { prismicPopupModal.data !== undefined ? <PopupModal allSlices={homepage.data.page_content} prismicPopupModal={prismicPopupModal.data} /> : <></>}
                <Footer
                  footerData={footerData.data}
                  pagesLinksData={allPrismicPage.edges.concat(allPrismicResource.edges)}
                />
              </>
            )
          }
        </div>
      </ErrorBoundary>
    );
  }
}

export default Index;

Index.propTypes = {
  data: PropTypes.object
};

export const pageQuery = graphql`
  query IndexQuery {
    homepage: prismicHomepage {
      uid
      data {
        homepage_banner {
          title {
            text
            html
          }
          tagline {
            text
            html
          }
          button_label {
            text
            html
          }
        }

        page_content {
          __typename
          ... on PrismicHomepagePageContentMetadataSection {
            slice_type
            primary {
              metadata_description {
                text
              }
              metadata_keywords {
                text
              }
              metadata_robots
            }
          }
          ... on PrismicHomepagePageContentPageOverview{
            slice_type
            primary{
              page_title{
                text
              }
            }
          }
          ... on PrismicHomepagePageContentHeroes {
            id
            slice_type
            items {
              hero_headline {
                text
              }
              hero_multiline_heading {
                text
                html
                raw {
                  type
                  text
                }
              }
              hero_multiline_description {
                text
                html
                raw {
                  type
                  text
                }
              }
              hero_font_color
              hero_button_text {
                text
              }
              hero_button_color
              hero_button_link {
                url
                document {
                  data {
                    resource_display_name {
                      text
                    }
                    page_path{
                      text
                    }
                    page_url {
                      text
                    }
                  }
                }
              }
              image_visibility_for_mobile
              hero_background_image {
                Tablet {
                  localFile {
                    childImageSharp {
                      fluid (quality: 90) {
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        originalImg
                        originalName
                      }
                    }
                  }
                }
                Desktop {
                  localFile {
                    childImageSharp {
                      fluid (quality: 90) {
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        originalImg
                        originalName
                      }
                    }
                  }
                }
                MobileHDPI {
                  localFile {
                    childImageSharp {
                      fluid (quality: 90) {
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        originalImg
                        originalName
                      }
                    }
                  }
                }
              }
              videoflag
              #video_url {
              #  url
              #}
            }
          }
          ... on PrismicHomepagePageContentFormDisplaySection {
            id
            slice_type
            primary {
              download_form_heading {
                text
              }
              download_form_description {
                text
              }
              submit_button_text {
                text
              }
              form_call_page
              api_url {
                text
              }
              node_api_url {
                text
              }
              success_message {
                text
              }
              failure_message {
                text
              }
              event {
                text
              }
              event_data {
                text
              }
              form_section_background_color
              form_section_heading_font_color
              form_section_subheading_font_color
              form_section_font_color
              form_section_button_background
              form_section_button_font_color
              form_identifier
            }
            items {
              field_label {
                text
              }
              field_type {
                text
              }
              field_required
              field_label_2 {
                text
              }
              field_type_2 {
                text
              }
              field_required_2
              field_label_3 {
                text
              }
              field_type_3 {
                text
              }
              field_required_3
            }
          }
          ... on PrismicHomepagePageContentPageHeaderBody {
            id
            slice_type
            primary {
              subheading {
                text
              }
              heading {
                text
              }
              body {
                text
                html
              }
              page_header_button_text{
                text
              }
              page_header_button_link {
                url
              }
              page_header_background_color
              page_header_font_color
            }
          }
          ... on PrismicHomepagePageContentCards {
            slice_type
            id
            primary {
              #card_section_background_image {
              #  url
              #}
              cards_per_row
              card_section_title {
                text
              }
              card_section_subtitle {
                text
              }
              breadcrumb_id {
                text
              }
              card_section_background_color
              card_section_font_color
              card_type
            }
            items {
              card_logo {
                url
                alt
              }
              #card_image {
              #  url
              #}
              card_heading {
                text
              }
              card_subheading {
                text
              }
              card_description {
                text
              }
              card_button_text {
                text
              }
              card_button_link {
                id
                url
              }
              icon_color
              on_hover_background_color
              card_background_color
              card_button_color
            }
          }

          ... on PrismicHomepagePageContentResources {
            id
            slice_type
            primary {
              resource_type
              resource_section_heading {
                text
              }
              resource_section_subheading {
                text
              }
              section_description {
                text
              }

              resource_button_text {
                text
              }
              career_button_text {
                text
              }
              career_button_link {
                url
              }
              resource_background_image {
                url
                alt
              }
              resource_button_link {
                url
              }
              section_heading_font_color
              section_subheading_font_color
              section_description_font_color
              section_button_background_color
              section_button_font_color
              career_button_font_color
              career_button_background_color
              resource_background_color
            }
            items {
              resource_uid {
                text
              }
              event_background_image {
                url
                alt
                Tablet {
                  alt
                  url
                }
              }
              event_size
              event_type
            }
          }
          ... on PrismicHomepagePageContentBannerTech1 {
            id
            slice_type
            primary {
              banner_tech_title {
                text
              }
              tech_background_color
              tech_font_color
            }
            items {
              partner_logo {
                url
                alt
              }
            }
          }
          ... on PrismicHomepagePageContentEmailTemplateLinks {
            id
            slice_type
            primary {
              form_identifier_for_teamplate
            }
            items {
              user_type
              email_title {
                text
              }
              email_template_link {
                url
                document {
                  data {
                    body {
                      __typename
                      ... on PrismicEmailTemplateBodyDefaultKeyValue {
                        id
                        slice_type
                        items {
                          key {
                            text
                          }
                          default_value {
                            text
                          }
                          multiline_default_value {
                            html
                            raw {
                              type
                              text
                            }
                          }
                        }
                      }
                      ... on PrismicEmailTemplateBodyRecipientList {
                        id
                        items {
                          team_name {
                            text
                          }
                          recipients {
                            text
                          }
                        }
                        slice_type
                      }
                    }
                    recipient {
                      text
                    }
                    cc_recipient {
                      text
                    }
                    bcc_recipient {
                      text
                    }
                    from_recipient {
                      text
                    }
                    subject {
                      text
                    }
                    salutation {
                      html
                      raw {
                        type
                        text
                      }
                    }
                    email_content{
                      html
                      raw {
                        type
                        text
                      }
                    }
                    signature {
                      html
                      raw {
                        type
                        text
                      }
                    }
                  }
                }
              }
              description {
                html
                raw {
                  type
                  text
                }
              }
            }
          }
        }
      }
    }
    allPrismicPage {
      edges {
        node {
          id
          uid
          data {
            page_display_name {
              text
            }
            page_path {
              text
            }
          }
        }
      }
    }
    allPrismicResource {
      edges {
        node {
          uid
          data {
            resource_display_name {
              text
            }
            page_path {
              text
            }
            body {
              __typename
              ... on PrismicResourceBodyPageOverview {
                primary {
                  page_thumbnail {
                    url
                    alt
                  }
                }
              }
              ... on PrismicResourceBodyResourceOverview {
                primary {
                  resource_type
                  title {
                    text
                  }
                }
              }
              ... on PrismicResourceBodyResourceTags {
                id
                prismicId
                slice_type
                primary {
                  tag_background_color
                  tag_font_color
                  tag_container_background_color
                  redirection_link {
                    url
                    uid
                    target
                    id
                  }
                  resource_card_tag_color
                }
                items {
                  tag_keywords_list
                }
              }
            }
          }
        }
      }
    }
    allPrismicJobDescriptionPage {
      edges {
        node {
          uid
          data {
            job_id {
              text
            }
            page_path {
              text
            }
            job_visible_on_homepage
            page_display_name {
              text
            }
            include_job_in_list
            body {
              __typename
              ... on PrismicJobDescriptionPageBodyJobDescription {
                id
                primary {
                  job_title {
                    text
                    html
                  }
                }
              }
              ... on PrismicJobDescriptionPageBodyKeyDetails {
                id
                primary {
                  job_location {
                    text
                    html
                  }
                }
              }
            }
          }
        }
      }
    }

    heroMenu: prismicHeromenu {
      id
      data {
        display_name {
          html
        }
        nav {
          primary {
            label {
              html
              text
            }
            link {
              url
            }
            label_font_color
            label_font_color_on_hover
            nav_item_background_color
            sub_link_separator_line_color
            sub_link_sub_text_font_color
            sub_link_type_font_color
            sub_nav_link_label_font_color
            nav_column_count
            nav_item_position
          }
          items {
            sub_nav_column_position
            sub_nav_link_lable {
              text
            }
            sub_nav_link {
              url
            }
            sub_link_sub_text {
              text
            }
            sub_link_type {
              text
            }
          }
        }
      }
    }
    footerData: prismicFooter {
      data {
        footer_background_color
        footer_font_color
        address {
          text
        }
        copyright_text {
          text
        }
        footer_logo {
          url
        }
        body {
          __typename
          ... on PrismicFooterBodySocialConnection {
            id
            slice_type
            primary {
              social_connection_type {
                text
              }
              social_connection_font_color
            }
            items {
              social_icon
              social_icon_font_color
              social_icon_link {
                url
              }
            }
          }
          ... on PrismicFooterBodyGspannPolicy {
            id
            slice_type
            items {
              policy_name {
                text
              }
              policy_link {
                url
              }
              policy_font_color
            }
          }
          ... on PrismicFooterBodyFooterMenu {
            id
            slice_type
            primary {
              footer_menu_type {
                text
              }
              footer_menu_link {
                url
              }
              menu_type_font_color
            }
            items {
              footer_menu_text {
                text
              }
              footer_menu_link {
                url
              }
              footer_menu_font_color
            }
          }
        }
      }
    }

    serviceContentCardData: prismicHomepagePageContentCards {
      items {
        card_heading {
          text
        }
        card_subheading {
          text
        }
        card_description {
          text
        }
        card_button_text {
          text
        }
      }
    }
    prismicPopupModal:
      prismicPopupModal {
        data {
          body {
            ... on PrismicPopupModalBodyEmailTemplateLinks {
              id
              slice_type
              primary {
                form_identifier_for_teamplate
              }
              items {
                user_type
                email_title {
                  text
                }
                email_template_link {
                  url
                  document {
                    data {
                      body {
                        __typename
                        ... on PrismicEmailTemplateBodyDefaultKeyValue {
                          id
                          slice_type
                          items {
                            key {
                              text
                            }
                            default_value {
                              text
                            }
                            multiline_default_value {
                              html
                              raw {
                                type
                                text
                              }
                            }
                          }
                        }
                        ... on PrismicEmailTemplateBodyRecipientList {
                          id
                          items {
                            team_name {
                              text
                            }
                            recipients {
                              text
                            }
                          }
                          slice_type
                        }
                      }
                      recipient {
                        text
                      }
                      cc_recipient {
                        text
                      }
                      bcc_recipient {
                        text
                      }
                      from_recipient {
                        text
                      }
                      subject {
                        text
                      }
                      salutation {
                        html
                        raw {
                          type
                          text
                        }
                      }
                      email_content{
                        html
                        raw {
                          type
                          text
                        }
                      }
                      signature {
                        html
                        raw {
                          type
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicPopupModalBodyFormDisplaySection {
              id
              slice_type
              prismicId
              items {
                field_label {
                  text
                }
                field_required
                field_type {
                  text
                }
              }
              slice_type
              primary {
                api_url {
                  text
                }
                download_form_description {
                  text
                }
                download_form_heading {
                  text
                }
                event {
                  text
                }
                event_data {
                  text
                }
                failure_message {
                  text
                }
                form_call_page
                form_identifier
                form_section_background_color
                form_section_button_background
                form_section_button_font_color
                form_section_font_color
                form_section_subheading_font_color
                form_section_heading_font_color
                node_api_url {
                  text
                }
                popup_image {
                  url
                }
                submit_button_text {
                  text
                }
                success_message {
                  text
                }
              }
            }
          }
        }
      }
    }
`;
